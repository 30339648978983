import React from 'react'
import { graphql } from 'gatsby'
import { formatTemplateData } from '@utils/templates'
import { camelCase } from '@utils/strings'
import Container from '@layout/Container'
import FlexComponents from '@layout/FlexComponents'

const FlexibleContentPage = ({ data }) => {
  const { wpPage } = data
  const {
    templateFlexible: { heroContent: heroFields, flexibleContent },
    title,
    slug: activeSlug,
    seo,
    uri,
  } = wpPage
  seo.path = uri
  const template = {
    activeSlug,
    title,
    heroFields,
    seo,
    designations: ['isFlexibleContentPage'],
    heroType: 'small',
    theme: 'light',
  }
  return (
    <Container template={formatTemplateData(template)}>
      {flexibleContent &&
        flexibleContent.map(({ __typename, ...componentProps }, index) => {
          const layout = camelCase(
            __typename.split('FlexibleContent_').slice(-1)[0]
          )

          return (
            <FlexComponents
              key={`${layout}-${index}`}
              layout={layout}
              componentProps={componentProps}
            />
          )
        })}
    </Container>
  )
}

export default FlexibleContentPage

export const pageQuery = graphql`
  query FlexibleContentPage($id: String!) {
    wpPage(id: { eq: $id }) {
      id
      title
      slug
      uri
      templateFlexible {
        heroContent {
          textContent {
            selectedFields
            heading
            content
            link {
              type
              text
              data {
                title
                url
                target
              }
            }
          }
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1600, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        flexibleContent {
          ... on WpPage_Templateflexible_FlexibleContent_ColumnsBlock {
            __typename
            columns {
              url
              selectedfields
              heading
              fieldGroupName
              content
            }
            textContent {
              content
              fieldGroupName
              link {
                data {
                  target
                  title
                  url
                }
                fieldGroupName
                text
                type
              }
              heading
              selectedFields
            }
          }
          ... on WpPage_Templateflexible_FlexibleContent_FormBlock {
            __typename
            formId
            textContent {
              selectedFields
              heading
              content
              link {
                data {
                  target
                  title
                  url
                }
                fieldGroupName
                file {
                  id
                  sourceUrl
                }
                text
                type
              }
            }
          }
          ... on WpPage_Templateflexible_FlexibleContent_Heading {
            __typename
            heading
            hasMedia
            media {
              videoUrl
              mediaType
              ratio
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1280, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
                mediaDetails {
                  width
                  height
                }
              }
            }
          }
          ... on WpPage_Templateflexible_FlexibleContent_ListBlock {
            containerWidth
            textContent {
              selectedFields
              heading
              content
            }
            list {
              label
              content
              link {
                title
                url
                target
              }
            }
          }
          ... on WpPage_Templateflexible_FlexibleContent_ListBlock {
            containerWidth
            textContent {
              selectedFields
              heading
              content
            }
            list {
              label
              content
              link {
                title
                url
                target
              }
            }
          }
          ... on WpPage_Templateflexible_FlexibleContent_Iframe {
            __typename
            embed
            containerWidth
          }
          ... on WpPage_Templateflexible_FlexibleContent_Gallery {
            caption
            containerWidth
            featuredStyle
            imageAlign
            gallery {
              localFile {
                publicURL
                childImageSharp {
                  # TODO: Alter sizing
                  fluid(maxWidth: 1920, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ... on WpPage_Templateflexible_FlexibleContent_MediaGrid {
            __typename
            mediaItems {
              media {
                videoUrl
                mediaType
                ratio
                image {
                  localFile {
                    childImageSharp {
                      fluid(maxWidth: 1920, quality: 90) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                      }
                    }
                  }
                  mediaDetails {
                    width
                    height
                  }
                }
              }
              caption
            }
            containerWidth
          }
          ... on WpPage_Templateflexible_FlexibleContent_Quote {
            content
            author
            authorMeta
          }
          ... on WpPage_Templateflexible_FlexibleContent_RelatedPosts {
            __typename
            postType
            hasFeatured
            containerWidth
            posts {
              ...TeaserPostFields
            }
            pages {
              heading
              backgroundColor
              imageType
              link {
                target
                title
                url
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Templateflexible_FlexibleContent_Slider {
            __typename
            sliderImages {
              id
              caption
              altText
              localFile {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 1600, quality: 90) {
                    aspectRatio
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
          ... on WpPage_Templateflexible_FlexibleContent_Spacer {
            __typename
          }
          ... on WpPage_Templateflexible_FlexibleContent_Text {
            __typename
            containerWidth
            contentAlign
            typeSize
            textContent {
              content
              fieldGroupName
              heading
              link {
                data {
                  target
                  title
                  url
                }
                fieldGroupName
                file {
                  id
                  sourceUrl
                }
                text
                type
              }
              selectedFields
            }
          }
          ... on WpPage_Templateflexible_FlexibleContent_TextWithRelatedPost {
            postType
            textContent {
              selectedFields
              heading
              content
            }
            posts {
              ...TeaserPostFields
            }
            pages {
              heading
              backgroundColor
              imageType
              link {
                target
                title
                url
              }
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 800) {
                      ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                  }
                }
              }
            }
          }
          ... on WpPage_Templateflexible_FlexibleContent_TextBanner {
            bannerText
            images {
              caption
              image {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      }
      seo {
        title
        twitterTitle
        twitterDescription
        twitterImage {
          description
          localFile {
            childImageSharp {
              fixed {
                src
              }
            }
          }
        }
        opengraphTitle
        opengraphImage {
          localFile {
            childImageSharp {
              fixed {
                src
              }
            }
          }
        }
        opengraphDescription
        metaRobotsNoindex
        metaRobotsNofollow
        metaDesc
      }
    }
  }
`
